import React from "react";
import {DataFrameView, GrafanaTheme2 as GrafanaTheme, PanelProps} from "@grafana/data";
import {LogPanelOptions} from "types";
import {css, cx} from "@emotion/css";
import {useStyles2 as useStyles, useTheme} from "@grafana/ui";

interface EopLogPanelProps extends PanelProps<LogPanelOptions> {
}

export class LogLine {
    private static WORDS_REGEX = /(?:^|\s+)?\S+(?:\s+|$)/g;
    private static MISMATCHED_WORDS_LIMIT = 2;
    private static MISMATCHED_WORDS_REL_LIMIT = 0.2;

    public content: { [option: string]: string }
    public time: number;
    public count: number;
    public similarMessages: string[];

    public constructor(dataFrame: { [option: string]: any }, private options: LogPanelOptions) {
        this.content = dataFrame;
        this.time = dataFrame.Time;
        this.count = 1;
        this.similarMessages = [];
    }

    public sameAs(other: LogLine): boolean {
        return this.getLevel() === other.getLevel()
            && this.getLogGroup() === other.getLogGroup()
            && this.getMessage() === other.getMessage()
            && this.getStacktrace() === other.getStacktrace();
    }

    public similarTo(other: LogLine): boolean {
        if (this.getLevel() !== other.getLevel()
            || this.getLogGroup() !== other.getLogGroup()
            || this.getStacktrace() !== other.getStacktrace()) {
            return false;
        }

        const words = this.getMessage().match(LogLine.WORDS_REGEX);
        const otherWords = other.getMessage().match(LogLine.WORDS_REGEX);

        if (!words || !otherWords || words.length !== otherWords.length) {
            return false;
        }

        let mismatchedWordsCount = 0;
        for (let i = 0; i < words.length; i++) {
            if (words[i] !== otherWords[i]) {
                mismatchedWordsCount++;
            }
        }

        return mismatchedWordsCount <= LogLine.MISMATCHED_WORDS_LIMIT
            && mismatchedWordsCount / words.length < LogLine.MISMATCHED_WORDS_REL_LIMIT;
    }

    public shortDate(): string {
        const date = new Date(this.time);
        return "".concat(date.getDate().toString().padStart(2, "0"), ".",
            (date.getMonth() + 1).toString().padStart(2, "0"), ". ",
            date.getHours().toString().padStart(2, "0"), ":",
            date.getMinutes().toString().padStart(2, "0")
        );
    }

    public shortLogGroup(): string {
        return this.getLogGroup()
            .replace("/ecs/eop-", "");
    }

    public getLevel(): string {
        return this.content[this.options.levelField];
    }

    public getLogGroup(): string {
        return this.content[this.options.logGroupField];
    }

    public getMessage(): string {
        return this.content[this.options.messageField];
    }

    public getStacktrace(): string {
        return this.content[this.options.stacktraceField];
    }
}

export class LogLinesMessageFilter {
    private messageRegexFilters: RegExp[] = [];

    public constructor() {
    }

    public matches(logLine: LogLine): boolean {
        return this.messageRegexFilters
            .filter(regex => regex.test(logLine.getMessage()))
            .length > 0;
    }

    public addMessageRegexFilterFromString(regexString: string): void {
        this.messageRegexFilters.push(new RegExp(regexString));
    }

    public withMessageRegexFilter(regex: RegExp): LogLinesMessageFilter {
        this.messageRegexFilters.push(regex);
        return this;
    }
}

export class LogPanel {
    public readonly ignoredCount: number;
    private readonly logLines: LogLine[];
    private readonly aggregatedLogLines: LogLine[];
    private readonly options: LogPanelOptions;

    public constructor(logLines: LogLine[], options: LogPanelOptions) {
        this.options = options;
        this.logLines = this.filterIgnoredLogMessages(logLines);
        this.ignoredCount = logLines.length - this.logLines.length;
        this.aggregatedLogLines = [];
        if (options.aggregateDuplicates) {
            this.aggregatedLogLines = this.aggregateDuplicateLogLines(this.logLines);
        }
    }

    public getLogLines(): LogLine[] {
        return this.options.aggregateDuplicates ? this.aggregatedLogLines : this.logLines;
    }

    public errorCount(): number {
        return this.logLines.filter(line => line.getLevel() === 'ERROR').length;
    }

    public warnCount(): number {
        return this.logLines.filter(line => line.getLevel() === 'WARN').length;
    }

    private filterIgnoredLogMessages(logLines: LogLine[]): LogLine[] {
        const logMessagesFilter = new LogLinesMessageFilter();
        this.options.ignoredLogMessages.forEach(message => logMessagesFilter.addMessageRegexFilterFromString(message));

        return logLines.filter(line => !logMessagesFilter.matches(line));
    }

    private aggregateDuplicateLogLines(logLines: LogLine[]): LogLine[] {
        const aggregated = [];
        nextLogLine: for (let currentIndex = 0; currentIndex < logLines.length; currentIndex++) {
            for (let j = 0; j < currentIndex; j++) {
                if (logLines[currentIndex].sameAs(logLines[j])) {
                    logLines[j].count++;
                    continue nextLogLine;
                }
                if (logLines[currentIndex].similarTo(logLines[j])) {
                    logLines[j].count++;
                    logLines[j].similarMessages.push(logLines[currentIndex].getMessage())
                    continue nextLogLine;
                }
            }
            aggregated.push(logLines[currentIndex]);
        }

        return aggregated;
    }
}

export const EopLogPanel: React.FC<EopLogPanelProps> = ({options, data, width, height}) => {
    const theme = useTheme();
    const styles = useStyles(getStyles);

    if (data.series.length === 0 || !data.series[0]) {
        return (
            <p>No logs found.</p>
        );
    }

    const view = new DataFrameView(data.series[0]);
    const logLines = view.toArray().map(item => new LogLine(item, options));
    const logPanel = new LogPanel(logLines, options);

    return (
        <div className={cx(styles.wrapper, css`
          width: ${width}px;
          height: ${height}px;
          `)}>
            <div>Errors: <span style={{color: theme.palette.redBase}}>{logPanel.errorCount()}</span>, Warns: <span
                style={{color: theme.palette.orange}}>{logPanel.warnCount()}</span>, Ignored: <span
                style={{color: theme.palette.gray60}}>{logPanel.ignoredCount}</span></div>
            <table>
                <thead>
                <tr className={cx(styles.tableHeadRow)}>
                    <th>Time</th>
                    <th>Level</th>
                    <th style={{minWidth: 120}}>Service</th>
                    <th>Message</th>
                    <th>Stacktrace</th>
                </tr>
                </thead>
                <tbody>
                {logPanel.getLogLines().map((line, index) => {
                    return <tr key={index} className={cx(styles.tableRow)}>
                        <td className={cx(styles.tableCell)}>{line.shortDate()}</td>
                        <td className={cx(styles.tableCell, styles.noWrap)}>{formatLevel(line.getLevel())}</td>
                        <td className={cx(styles.tableCell)}>{line.shortLogGroup()}</td>
                        <td className={cx(styles.tableCell)}>{formatDuplicateCount(line.count)}{formatMessage(line)}</td>
                        <td className={cx(styles.tableCell)}>{formatStacktrace(line.getStacktrace())}</td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
    );

    function formatLevel(level: string) {
        const color = level === 'ERROR' ? theme.palette.redBase : theme.palette.orange;
        return <span style={{color: color}}>{level}</span>;
    }

    function formatDuplicateCount(count: number) {
        if (options.aggregateDuplicates && count) {
            return <span>({count}) </span>;
        } else {
            return '';
        }
    }

    function formatMessage(line: LogLine) {
        if (line.similarMessages.length > 0) {
            const similarLines = line.similarMessages.join("\n");
            return <span className={css(`text-decoration: underline`)} title={similarLines}>{line.getMessage()}</span>;
        } else {
            return line.getMessage();
        }
    }

    function formatStacktrace(stacktrace: string) {
        if (stacktrace && stacktrace !== '') {
            return <span className={css(`text-decoration: underline`)} title={stacktrace}>Stacktrace</span>;
        } else {
            return '';
        }
    }
};

const getStyles = (_: GrafanaTheme) => {
    return {
        wrapper: css({
            position: "relative",
            overflowY: "scroll"
        }),
        tableCell: css({
            verticalAlign: "text-top",
            paddingRight: "8px"
        }),
        noWrap: css({
            whiteSpace: "nowrap"
        }),
        tableRow: css({
            borderBottom: "2px solid #333"
        }),
        tableHeadRow: css({
            borderBottom: "2px solid #333"
        }),
    };
};
